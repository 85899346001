@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), local("Helvetica"), url("/fonts/helvetica.woff") format("woff"), url("/fonts/helvetica.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 12px;
  background-color: #f1f1f1;
}

.hidden {
  display: none;
}

strong {
  color: #ff5722;
}

@import './animation.css';
@import './button.css';
@import './form-item.css';
@import './components/app.css';
@import './components/header.css';
@import './components/main.css';
@import './components/popup.css';
