@-webkit-keyframes move {
  from {
    -webkit-transform: translate3d(0px, -50px, 0px);
            transform: translate3d(0px, -50px, 0px);
  }

  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
}

@keyframes move {
  from {
    -webkit-transform: translate3d(0px, -50px, 0px);
            transform: translate3d(0px, -50px, 0px);
  }

  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
}

@-webkit-keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.8;
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.8;
  }
}

@-webkit-keyframes click {
	0% {
		-webkit-transform: scale(0.5);
		        transform: scale(0.5);
	}
	25%, 50% {
		opacity: 1;
	}
	to {
		opacity: 0;
		-webkit-transform: scale(1.3);
		        transform: scale(1.3);
	}
}

@keyframes click {
	0% {
		-webkit-transform: scale(0.5);
		        transform: scale(0.5);
	}
	25%, 50% {
		opacity: 1;
	}
	to {
		opacity: 0;
		-webkit-transform: scale(1.3);
		        transform: scale(1.3);
	}
}

@-webkit-keyframes popup {
  from {
    -webkit-transform: translate3d(0px, 100px, 0px);
            transform: translate3d(0px, 100px, 0px);
  }

  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
}

@keyframes popup {
  from {
    -webkit-transform: translate3d(0px, 100px, 0px);
            transform: translate3d(0px, 100px, 0px);
  }

  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
}
.button {
  position: relative;
  box-sizing: border-box;
  min-width: 88px;
  min-height: 36px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25em;
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 2px;
  background-color: #e64a19;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  color: #ffffff;
  cursor: pointer;
  outline: none;
}

.button:disabled {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  cursor: auto;
}

.button:disabled:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.button::after {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  content: '';
  opacity: 0;
  background: rgba(255, 255, 255, 0.1);
}

.button:hover {
  background-color: #ff3d00;
}

.button:active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.button:active::after {
  -webkit-animation: click 225ms;
          animation: click 225ms;
}
.form-item {
  position: relative;
  font-size: 12px;
}

/*Должно появляться при фокусе на form-input*/
.form-item::after {
  position: absolute;
  bottom: -1px;
  left: 0;
  content: '';
  width: 100%;
  height: 2px;
  background: #000000;

  transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.form-item:focus-within::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
/*Срабатывает при уведении фокуса*/
.form-item:not(:focus-within).form-item::after {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}

.form-input {
  position: relative;
  box-sizing: content-box;
  display: block;
  width: 100%;
  height: 1em;
  margin: 0;
  margin-top: 15px;
  padding: 7px 0 9px;
  vertical-align: middle;
  font-family: 'Arial', sans-serif;
  font-size: 1em;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.9);
  outline: none;
}

.form-label {
  position: absolute;
  top: 22px;
  left: 0;
  color: rgba(0, 0, 0, 0.54);
  outline: none;
  transition: all 225ms;
}

.form-input::-webkit-input-placeholder {
  visibility: hidden;
  font-family: inherit;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.3);
  opacity: 0;
}

.form-input::-ms-input-placeholder {
  visibility: hidden;
  font-family: inherit;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.3);
  opacity: 0;
}

.form-input::placeholder {
  visibility: hidden;
  font-family: inherit;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.3);
  opacity: 0;
}

.form-input:focus::-webkit-input-placeholder {
  visibility: visible;
  opacity: 1;
  transition: all 225ms;
}

.form-input:focus::-ms-input-placeholder {
  visibility: visible;
  opacity: 1;
  transition: all 225ms;
}

.form-input:focus::placeholder {
  visibility: visible;
  opacity: 1;
  transition: all 225ms;
}

.form-item .form-input:focus + .form-label,
.form-item .form-input:not(:placeholder-shown) + .form-label {
  top: 0;
  font-size: 9px;
  color: rgba(0, 0, 0, 0.7);
  transition: all 225ms;
}

.app__container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  text-align: center;
}
.header {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0 0 72px;
          flex: 0 0 72px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 12px;
  background-color: #373e52;
}

.header__button {
  position: relative;
  box-sizing: border-box;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

.header__button::after {
  position: absolute;
	top: 0;
  left: 0;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	content: '';
	opacity: 0;
  background: rgba(255,255,255,0.1);
  color: #ffffff;
}

.header__button:active::after {
	-webkit-animation: click 225ms;
	        animation: click 225ms;
}

.header__button svg {
  width: 40px;
  height: 40px;
  fill: #f1f1f1;
}

.header__button--restart {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 24px;
  color: #ffffff;
}

.header__button--restart::after {
  width: 160px;
}

.header__price {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 0;
  margin-left: -.04em;
  font-size: 42px;
  font-weight: 400;
  line-height: 1.303579em;
  letter-spacing: -.02em;
  text-align: center;
  color: #ff5722;
  -webkit-animation: move 225ms;
          animation: move 225ms;
}

.header__step-progress {
  font-family: sans-serif;
  font-size: 36px;
  text-align: center;
  color: #ffffff;
}

@media(min-width: 900px) {
  .header__price {
    font-size: 63px;
  }
}

@media(min-width: 1200px) {
  .header {
    font-size: 20px;
  }

  .header__price {
    font-size: 3.5em;
  }
}

.main {
  max-width: 90%;
  margin: auto;
  color: #373e52;
}

.main__container {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 8px;
  -webkit-animation: opacity 600ms;
          animation: opacity 600ms;
}

.main__container--result {
  margin-top: 20px;
  padding: 0;
}

.main__img {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 20px;
  margin-bottom: 15px;
}

.main__svg {
  width: 100px;
  height: 101px;
}

.main__title {
  margin: 15px;
  font-size: 2.8125em;
  font-weight: 400;
  line-height: 1.06667em;
  text-transform: uppercase;
  text-align: center;
}

.main__desc {
  font-size: 1em;
  line-height: 1.5em;
  text-align: center;
  margin: 16px 0;
}

.main__start-button {
  padding: 21px 40px;
}

.main__start-button::after {
  width: 180px;
  height: 60px;
}

.main__question-img {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-bottom: 31px;
  border-radius: 50%;
  background-color: #e2e2e2;
}

.main__question-svg {
  width: 60px;
  height: 60px;
}

.main__question-title {
  margin: 0;
  margin-bottom: 32px;
  font-size: 2.8125em;
  font-weight: 400;
  line-height: 1.06667em;
  text-align: center;
}

.main__question-desc {
  box-sizing: border-box;
  max-width: 800px;
  margin: 0;
  margin-bottom: 45px;
  font-size: 1em;
  line-height: 1.5em;
  text-align: center;
}

.main__question-buttons {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 160px;
}

.main__question-button {
  box-sizing: border-box;
  width: 70px;
  height: 70px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: #373e52;
  cursor: pointer;
  outline: none;
}

.main__question-button:hover {
  opacity: 0.9;
}

.main__result {
  width: 100%;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.main__result-container {
  padding: 16px;
}

.main__result-title {
  margin: 0;
  margin-left: -0.04em;
  margin-bottom: 16px;
  font-size: 2.8125em;
  font-weight: 400;
  line-height: 1.06667em;
}

.main__result-price {
  display: block;
  margin-left: -0.04em;
  font-size: 3.5em;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 1.30357em;
  color: #ff5722;
}

.main__result-comment {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.main__result-asterisk {
  font-size: 0.75em;
  font-weight: 400;
  line-height: 1.375em;
}

.main__result-button,
.main__info-button {
  box-sizing: border-box;
  -webkit-align-self: center;
          align-self: center;
  min-width: 88px;
  min-height: 36px;
  padding: 8px 16px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4em;
  text-transform: uppercase;
  border: none;
  border-radius: 2px;
  color: #e64a19;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

.main__result-button:hover,
.main__info-button:hover {
  background-color: rgba(230, 74, 25, 0.12);
  transition: background-color 250ms;
}

.main__result-button:active,
.main__info-button:active {
  background-color: rgba(230, 74, 25, 0.3);
  transition: background-color 250ms;
}

.main__feedback {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

.main__feedback-item {
  width: 218px;
  margin: 16px;
  margin-bottom: 8px;
}

.main__feedback-button {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  margin-bottom: 5px;
  padding: 8px 16px;
  padding-top: 7px;
  line-height: 1.4em;
}

.main__feedback-button::after {
  width: 218px;
  height: 36px;
}

.main__info {
  padding: 5px 16px;
  padding-bottom: 24px;
}

.main__info-description {
  margin: 0;
  margin-top: 6px;
  line-height: 1.5em;
}

.main--result .main__svg {
  margin: 32px;
}

.main__sent {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1.71429em;
  color: #373e52;
}

.main__sent svg {
  width: 24px;
  height: 24px;
  fill: #4acc9a;
}

@media (min-width: 900px) {
  .main {
    max-width: 800px;
    font-size: 18px;
  }

  .main__info {
    padding-top: 10px;
  }
}

@media (min-width: 1200px) {
  .main {
    font-size: 20px;
  }

  .main__question-desc {
    padding: 0 10px;
  }
}

.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(0, 0, 0, 0.54);
  z-index: 98;
}

.popup__wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  -webkit-flex-basis: auto;
          flex-basis: auto;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  margin: 32px;
  border-radius: 2px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  -webkit-animation: popup 400ms;
          animation: popup 400ms;
}

.popup__container {
  position: relative;
  box-sizing: border-box;
  padding: 32px;
  font-size: 12px;
  overflow-y: auto;
  z-index: 99;
}

.popup__title {
  margin: 0;
  margin-top: 20px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 2.125em;
  font-weight: 400;
  line-height: 1.20588em;
  margin-left: -0.04em;
  padding: 10px;
  text-align: center;
  color: #00a8ea;
}

.popup__list {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  margin: 0;
  padding: 0;
  padding: 8px 0;
  list-style: none;
}

.popup__item {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-width: 260px;
  padding: 12px 16px;
}

.popup__item:hover {
  background-color: rgba(0, 0, 0, 0.12);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.popup__item-img {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f1f1f1;
}

.popup__item-img svg {
  width: 24px;
  height: 24px;
}

.popup__desc {
  padding: 0 16px;
}

.popup__question {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5em;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}

.popup__answer {
  margin: 0;
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.46429em;
  text-align: left;
  color: rgba(0, 0, 0, 0.54);
}

.popup__form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.popup__form .form-item {
  margin-bottom: 8px;
  padding-top: 16px;
}

.popup__form .form-input {
  margin-top: 15px;
}

.popup__form .form-label {
  top: 38px;
}

.popup__form .form-item .form-input:focus + .form-label,
.popup__form .form-item .form-input:not(:placeholder-shown) + .form-label {
  top: 15px;
  font-size: 9px;
  color: rgba(0, 0, 0, 0.7);
  transition: all 225ms;
}

.popup__form-button {
  -webkit-align-self: center;
          align-self: center;
  width: 260px;
}

.popup__close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 48px;
  height: 48px;
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.popup__close-button::after {
  visibility: hidden;
  position: absolute;
  content: '';
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: none;
}

.popup__close-button:active::after {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-animation: click 225ms;
          animation: click 225ms;
}

.popup__close-button svg {
  width: 40px;
  height: 40px;
}

@media (min-width: 900px) {
  .popup__container {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .popup__container {
    font-size: 20px;
  }
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), local("Helvetica"), url("/fonts/helvetica.woff") format("woff"), url("/fonts/helvetica.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 12px;
  background-color: #f1f1f1;
}

.hidden {
  display: none;
}

strong {
  color: #ff5722;
}

