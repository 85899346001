@keyframes move {
  from {
    transform: translate3d(0px, -50px, 0px);
  }

  to {
    transform: translate3d(0px, 0px, 0px);
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.8;
  }
}

@keyframes click {
	0% {
		transform: scale(0.5);
	}
	25%, 50% {
		opacity: 1;
	}
	to {
		opacity: 0;
		transform: scale(1.3);
	}
}

@keyframes popup {
  from {
    transform: translate3d(0px, 100px, 0px);
  }

  to {
    transform: translate3d(0px, 0px, 0px);
  }
}