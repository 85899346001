.button {
  position: relative;
  box-sizing: border-box;
  min-width: 88px;
  min-height: 36px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25em;
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 2px;
  background-color: #e64a19;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  color: #ffffff;
  cursor: pointer;
  outline: none;
}

.button:disabled {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  cursor: auto;
}

.button:disabled:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.button::after {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  content: '';
  opacity: 0;
  background: rgba(255, 255, 255, 0.1);
}

.button:hover {
  background-color: #ff3d00;
}

.button:active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.button:active::after {
  animation: click 225ms;
}