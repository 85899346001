.form-item {
  position: relative;
  font-size: 12px;
}

/*Должно появляться при фокусе на form-input*/
.form-item::after {
  position: absolute;
  bottom: -1px;
  left: 0;
  content: '';
  width: 100%;
  height: 2px;
  background: #000000;

  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.form-item:focus-within::after {
  transform: scaleX(1);
}
/*Срабатывает при уведении фокуса*/
.form-item:not(:focus-within).form-item::after {
  transform: scaleX(0);
}

.form-input {
  position: relative;
  box-sizing: content-box;
  display: block;
  width: 100%;
  height: 1em;
  margin: 0;
  margin-top: 15px;
  padding: 7px 0 9px;
  vertical-align: middle;
  font-family: 'Arial', sans-serif;
  font-size: 1em;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.9);
  outline: none;
}

.form-label {
  position: absolute;
  top: 22px;
  left: 0;
  color: rgba(0, 0, 0, 0.54);
  outline: none;
  transition: all 225ms;
}

.form-input::placeholder {
  visibility: hidden;
  font-family: inherit;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.3);
  opacity: 0;
}

.form-input:focus::placeholder {
  visibility: visible;
  opacity: 1;
  transition: all 225ms;
}

.form-item .form-input:focus + .form-label,
.form-item .form-input:not(:placeholder-shown) + .form-label {
  top: 0;
  font-size: 9px;
  color: rgba(0, 0, 0, 0.7);
  transition: all 225ms;
}
